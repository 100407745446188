<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col v-resize="onResize">
                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <template v-if="isCamp">
                            <h1 class="text-h3 font-weight-bold white--text my-3">
                                캠 핑 소 개
                            </h1>
                            <p class="text-h5 font-weight-bold white--text my-3">
                                <a @click="goToPage" style="color:#FFEE58;">[펜션 보기]</a>
                            </p>
                        </template>
                        <template v-else>
                            <h1 class="text-h3 font-weight-bold white--text my-3">
                                <!--{{this.roomInfo.roomNm}}-->
                                펜 션 소 개
                            </h1>
                            <p class="text-h5 font-weight-bold white--text my-3">
                                <a @click="goToPage" style="color:#FFEE58;">[캠핑 보기]</a>
                            </p>
                        </template>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <template v-if="isCamp">
                            <h1 class="text-h3 font-weight-bold white--text my-3">
                                캠 핑 소 개
                            </h1>
                            <p class="text-h5 font-weight-bold white--text my-3">
                                <a @click="goToPage" style="color:#FFEE58;">[펜션 보기]</a>
                            </p>
                        </template>
                        <template v-else>
                            <h1 class="text-h3 font-weight-bold white--text my-3">
                                <!--{{this.roomInfo.roomNm}}-->
                                펜 션 소 개
                            </h1>
                            <p class="text-h5 font-weight-bold white--text my-3">
                                <a @click="goToPage" style="color:#FFEE58;">[캠핑 보기]</a>
                            </p>
                        </template>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="pa-3">
            <v-col>
                <v-container class="">
                    <v-row>
                        <v-col cols="12" class="hidden-sm-and-down">
                            <ul style="padding-inline-start: 0">
                                <li v-for="(item, i) in roomList" :key="i" class="roomSubMenu_li"
                                >
                                    <a :id="item.roomId" @click="menuClick(item)"> {{item.roomNm}}</a>
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12" class="hidden-md-and-up">
                            <template v-if="roomList.length > 5">
                                <li v-for="(item, i) in roomList" :key="i" class="roomSubMenu_li"
                                    style="width: calc((100% - 40px) / 4);">
                                    <a :id="'_'+item.roomId" @click="menuClick(item)"> {{item.roomNm}}</a>
                                </li>
                            </template>
                            <template v-else>
                                <ul style="padding-inline-start: 0">
                                    <li v-for="(item, i) in roomList" :key="i" class="roomSubMenu_li"
                                        style="width: calc((100% - 40px) / 4);">
                                        <a :id="'_'+item.roomId" @click="menuClick(item)"> {{item.roomNm}}</a>
                                    </li>
                                </ul>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0">
                            <div data-aos="fade-up" data-aos-duration="700" data-aos-offset="300" data-aos-delay="150">
                                <swiper class="swiper"
                                        :options="swiperOption2"
                                        ref="roomSwiper"
                                >
                                    <template v-for="(room, i) in roomInfo.roomImg">
                                        <swiper-slide>
                                            <img class="main-slide-img-2" :src="room.roomImgRoute"
                                                 @click="goToRoomInfo(room)"/>
                                        </swiper-slide>
                                    </template>
                                    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                                    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                                    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                                </swiper>
                            </div>
                        </v-col>
                    </v-row>
                    <!--<v-row>
                        <v-col cols="12">
                            <v-carousel
                                    :continuous="true"
                                    cycle
                                    hide-delimiter-background
                                    :height="$vuetify.breakpoint.xs ? 300 : 700"
                                    style="border-radius: 15px !important;"
                            >
                                <v-carousel-item
                                        v-for="(roomImg, i) in roomInfo.roomImg"
                                        :key="i"
                                        :src="roomImg.roomImgRoute"
                                        inset

                                >
                                </v-carousel-item>
                            </v-carousel>
                        </v-col>
                    </v-row>-->
                    <v-row v-if="!isCamp">
                        <v-col>
                            <v-col cols="12" md="12" class="pb-0">
                                <h1 class="about-sub-title">객실시설</h1>
                            </v-col>
                        </v-col>
                        <v-col cols="12">
                            <v-col class="pa-1" style="background: #eaeaea;">
                                <template v-for="item in roomItem">
                                    <div class="roomItemImg">
                                        <img :src='item.roomItemImg' style="max-width: 40px;">
                                    </div>
                                </template>
                            </v-col>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="default-text my-5">
                            고객님의 즐거운 여행과 안락한 휴식을 위한 <span style="color: forestgreen; font-size: 1.3rem;">계곡일번지가</span>
                            되겠습니다.
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12" class="mb-5">
                            <h1 class="about-sub-title">이용요금</h1>
                        </v-col>
                        <v-row>
                            <v-col cols="12" class="hidden-sm-and-down">
                                <ul style="width:100%; margin:0 auto !important;" class="roundBtn-blue calc-4">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li class="" style="">
                                        <a @click="btnClick(1)" style="font-weight: bold; border: 2px solid #1976d2;">예약하기</a>
                                    </li>
                                </ul>
                            </v-col>
                            <v-col cols="12" class="hidden-md-and-up">
                                <ul style="width:100%; margin:0 auto !important;" class="roundBtn-blue calc-1">
                                    <li>
                                        <a @click="btnClick(1)"
                                           style="font-weight: bold; border: 2px solid #1976d2;">예약하기</a>
                                    </li>
                                </ul>
                            </v-col>

                        </v-row>
                        <v-col cols="12" md="12" class="hidden-sm-and-down">
                            <table class="table-room" border="1">
                                <tr>
                                    <th>객실명</th>
                                    <th>인원</th>
                                    <th>평형</th>
                                    <th>형태</th>
                                    <th>구분</th>
                                    <th>비수기</th>
                                    <th>준성수기</th>
                                    <th>성수기</th>
                                </tr>
                                <tr>
                                    <td rowspan="2">{{this.roomInfo.roomNm}}</td>
                                    <td rowspan="2">기준{{this.roomInfo.stdNop}}/최대{{this.roomInfo.maxNop}}</td>
                                    <td rowspan="2">{{this.roomInfo.roomArc}}</td>
                                    <td rowspan="2">{{this.roomInfo.roomForm}}</td>
                                    <td>주중</td>
                                    <td>{{this.roomInfo.osrWe | comma}}</td>
                                    <td>{{this.roomInfo.spsrWe | comma}}</td>
                                    <td>{{this.roomInfo.psrWe | comma}}</td>
                                </tr>
                                <tr>
                                    <td>주말</td>
                                    <td>{{this.roomInfo.osrWd | comma}}</td>
                                    <td>{{this.roomInfo.spsrWd | comma}}</td>
                                    <td>{{this.roomInfo.psrWd | comma}}</td>
                                </tr>
                            </table>
                        </v-col>

                        <v-col cols="12" md="12" class="hidden-md-and-up">
                            <table class="table-room" border="1">
                                <tr>
                                    <th>객실명</th>
                                    <th>인원</th>
                                    <th>평형</th>
                                    <th>형태</th>
                                </tr>
                                <tr>
                                    <td rowspan="2">{{this.roomInfo.roomNm}}</td>
                                    <td rowspan="2">기준{{this.roomInfo.stdNop}}/최대{{this.roomInfo.maxNop}}</td>
                                    <td rowspan="2">{{this.roomInfo.roomArc}}</td>
                                    <td rowspan="2">{{this.roomInfo.roomForm}}</td>
                                </tr>
                            </table>
                            <table class="table-room mt-8" border="1">
                                <tr>
                                    <th>구분</th>
                                    <th>비수기</th>
                                    <th>준성수기</th>
                                    <th>성수기</th>
                                </tr>
                                <tr>
                                    <td>주중</td>
                                    <td>{{this.roomInfo.osrWe | comma}}</td>
                                    <td>{{this.roomInfo.spsrWe | comma}}</td>
                                    <td>{{this.roomInfo.psrWe | comma}}</td>
                                </tr>
                                <tr>
                                    <td>주말</td>
                                    <td>{{this.roomInfo.osrWd | comma}}</td>
                                    <td>{{this.roomInfo.spsrWd | comma}}</td>
                                    <td>{{this.roomInfo.psrWd | comma}}</td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="roomInfoDesc">
                                <ul>
                                    <li>
                                        성수기: {{peakInfo.peak1}}
                                    </li>
                                    <li>
                                        준성수기: {{peakInfo.peak2}}
                                    </li>
                                    <li>
                                        기준인원 외 1인 초과당 추가요금 5,000원이 적용됩니다.
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="isCamp">
                        <v-col>
                            <v-row class="my-8">
                                <v-col cols="12" md="12">
                                    <h1 class="about-sub-title">일번지 캠핑장의 특장점</h1>
                                </v-col>
                                <v-col>
                                    <div class="ql-editor">
                                        <span v-html="textCamp"></span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="my-8" v-if="isCampA">
                                <v-col cols="12" md="12">
                                    <h1 class="about-sub-title">일번지 캠핑장-{{roomGubun}}의 차별화</h1>
                                </v-col>
                                <v-col>
                                    <div class="ql-editor">
                                        <span v-html="textCampDiff"></span>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col>
                            <v-row class="my-8">
                                <v-col cols="12" md="12">
                                    <h1 class="about-sub-title">일번지 펜션의 특장점</h1>
                                </v-col>
                                <v-col>
                                    <div class="ql-editor">
                                        <span v-html="textRoom"></span>
                                    </div>
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="hidden-sm-and-down">
                            <ul style="width:100%; margin:0 auto !important;" class="roundBtn-blue calc-3">
                                <li></li>
                                <li>
                                    <a @click="btnClick(1)"
                                       style="font-weight: bold; border: 2px solid #1976d2;">예약하기</a>
                                </li>
                                <li></li>
                            </ul>
                        </v-col>
                        <v-col cols="12" class="hidden-md-and-up">
                            <ul style="width:100%; margin:0 auto !important;" class="roundBtn-blue calc-1">
                                <li>
                                    <a @click="btnClick(1)"
                                       style="font-weight: bold; border: 2px solid #1976d2;">예약하기</a>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    export default {
        name: 'RoomInfo',
        components: {
            Swiper,
            SwiperSlide
        },
        data: () => ({
            roomId: "",
            roomGubun: "",
            roomInfo: {},
            roomList: [],
            roomImg: [],
            roomItem: [],
            peakList: [],
            peakInfo: {
                peak1: "",
                peak2: "",
            },
            isCamp: false,
            isCampA: false,
            comnList: [],
            textRoom: "",
            textCamp: "",
            textCampDiff: "",
            isMob: false, // 화면 모바일인지 확인

            swiperOption: {
                centeredSlides: true,
                slidesPerView: 1.1,
                loop: true,
                spaceBetween: 10,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },

                }
            },
            swiperOption2: {
                loopAdditionalSlides:3,
                spaceBetween: 10,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },

                }
            },
        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);

            this.roomId = this.$getParameterByName('roomId');
            this.roomGubun = this.$getParameterByName('roomGubun');

            // 객실 ID 유무 확인
            if (this.roomId === "" || this.roomGubun === "") {
                alert("객실 정보가 없습니다. 다시 시도해주세요.");
                window.history.back();
            }

            // 객실 ID 에따른 데이터 불러오기
            switch (this.roomGubun) {
                case "R" : {
                    this.isCamp = false;
                    this.getRoomInfo();
                    break;
                }
                case "A":
                case "B":
                case "C":
                case "D": {
                    this.isCamp = true;
                    this.getCampList(this.roomGubun);
                    break;
                }
                default : {
                    alert("객실 정보가 없습니다. 다시 시도해주세요.");
                    break;
                }
            }

            // 창크기 체크
            this.onResize();

            // 성수기
            this.getPeackList();

            // 객실 설명
            this.getRoomDesc();

        },
        methods: {
            onResize() {

                let isMob = false;

                if (window.innerWidth < 700) {
                    isMob = true;
                } else {
                    isMob = false;
                }

                if(this.isMob !== isMob){

                    this.isMob = isMob;

                    // 객실 설명0
                    this.getRoomDesc();
                }
            },
            getRoomInfo() {
                let fdata = {
                    roomId: this.roomId
                };

                return this.$store.dispatch("room/getRoomInfo", fdata)
                    .then((resp) => {
                        this.roomInfo = resp.message[0];

                        this.getRoomData();
                        setTimeout(() => {

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            getRoomData() {

                // 상단 메뉴 불러오기
                if (this.roomInfo.roomGubun === "R") {
                    this.getRoomList({roomGubun: "R", useYn: "Y"});
                    this.roomImgList = this.roomInfo.roomImg;
                } else {
                    this.getCampList(this.roomInfo.roomGubun);
                }

            },
            getRoomList(val) {
                return this.$store.dispatch("room/getRoomList", val)
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;

                            for (let i = 0; i > resp.message.length; i++) {

                                if (resp.message[i].roomId === this.roomInfo.roomId) {
                                    this.roomInfo = resp.message[i];
                                }

                            }

                            this.getRoomItem();
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            getCampList() {

                return this.$store.dispatch("room/getCampList", {useYn: "Y"})
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;
                            this.getCampInfo()
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            getCampInfo() {

                if (this.roomGubun === 'A' || this.roomGubun === 'D') {
                    this.isCampA = true;
                }

                for (let i = 0; this.roomList.length > i; i++) {

                    if (this.roomList[i].roomGubun === this.roomGubun) {
                        this.roomInfo = this.roomList[i];

                    }
                }

            },
            getRoomItem() {

                if (this.roomInfo.roomItem == undefined || this.roomInfo.roomItem.charAt(0) !== "[") {
                    return;
                }

                this.roomItem = JSON.parse(this.roomInfo.roomItem);

            },
            getPeackList() {
                return this.$store.dispatch("admin/getPeakList", {useYn: "Y"})
                    .then((resp) => {
                        setTimeout(() => {

                            this.peakList = resp.message;

                            for (let i = 0; this.peakList.length > i; i++) {

                                let date = this.peakList[i].peakStartDate + " ~ " + this.peakList[i].peakEndDate;

                                switch (this.peakList[i].peakGubun) {
                                    case "1" : {   // 성수기

                                        if (this.peakInfo.peak1.length > 0) {
                                            this.peakInfo.peak1 = this.peakInfo.peak1 + " , " + date;
                                        } else {
                                            this.peakInfo.peak1 = date;
                                        }
                                        break;
                                    }
                                    case "2" : {  // 준성수기

                                        if (this.peakInfo.peak2.length > 0) {
                                            this.peakInfo.peak2 = this.peakInfo.peak2 + " , " + date;
                                        } else {
                                            this.peakInfo.peak2 = date;
                                        }
                                        break;
                                    }
                                }
                            }

                        }, 300)
                    })
                    .catch((err) => {

                    })
            },
            getRoomDesc() {   // 설명 가져오기
                let formdata = {comnGubuns: ['textRoom', 'textCamp', 'textCampDiff'],}

                if (this.isMob) {

                    formdata['comnOrder'] = '1';

                } else {
                    formdata['comnOrder'] = '0';
                }

                return this.$store.dispatch("admin/getComn", formdata)
                    .then((resp) => {

                        if (resp.message === undefined) {
                            alert("잘못된 요청입니다.");
                            window.history.back();
                            return;
                        }

                        this.comnList = resp.message;

                        let _this = this;

                        this.comnList.forEach(function (e) {

                            switch (e.comnGubun) {
                                case 'textRoom': {
                                    _this.textRoom = e.comnCont;

                                    break;
                                }
                                case 'textCamp': {
                                    _this.textCamp = e.comnCont;
                                    break;
                                }
                                case 'textCampDiff': {
                                    _this.textCampDiff = e.comnCont;
                                    break;
                                }
                            }

                        });

                        //this.quill.root.innerHTML = this.comnInfo.comnCont;

                    })
                    .catch((err) => {
                    })

            },
            menuClick(item) {

                location.href = "/place/roomList/roomInfo" + '?roomId=' + item.roomId + '&roomGubun=' + item.roomGubun;

            },
            btnClick(val) {

                switch (val) {
                    case 0 : {
                        this.$router.push({name: "RoomList"});
                        break;
                    }
                    case 1: {
                        this.$router.push({name: "ReservationSearch", params: this.roomInfo});
                        break;
                    }
                }

            },
            goToPage() {
                let roomId = "";
                let roomGubun = "";
                if (this.isCamp) {
                    roomId = "01";
                    roomGubun = "R";
                } else {
                    roomId = "A00";
                    roomGubun = "A";
                }
                location.href = "/place/roomList/roomInfo" + '?roomId=' + roomId + '&roomGubun=' + roomGubun;
            }
        },
        mounted() {
        },
        updated: function () {  // 페이지 전부 생성후 자동 실행
            this.$nextTick(function () {
                //$('.roomSubMenuTable > tr > th').removeClass("active");
                $('.roomSubMenu_li > a').removeClass("active");
                $("#" + this.roomInfo.roomId).addClass("active");

                $("#_" + this.roomInfo.roomId).addClass("active");

            })
        }

    }
</script>

